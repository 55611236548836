import { ready, getParameterByName } from "./app.js";

jQuery(window).load(function () {
   $("#preloader").fadeOut("slow");
});

$(document).ready(function () {
   /* ========================================================================= */
   /*	Menu item highlighting
    /* ========================================================================= */

   jQuery("#nav").singlePageNav({
      offset: jQuery("#nav").outerHeight(),
      filter: ":not(.external)",
      speed: 1200,
      currentClass: "current",
      easing: "easeInOutExpo",
      updateHash: true,
      beforeStart: function () {
         console.log("begin scrolling");
      },
      onComplete: function () {
         console.log("done scrolling");
      },
   });

   $(window).scroll(function () {
      if ($(window).scrollTop() > 400) {
         $("#navigation").css("background-color", "rgba(16, 22, 54, 0.95)");
      } else {
         $("#navigation").css("background-color", "rgba(16, 22, 54, 0.2)");
      }
   });

   $(window).scroll(function () {
      if ($(window).scrollTop() > 400) {
         $("#back-top").fadeIn(200);
      } else {
         $("#back-top").fadeOut(200);
      }
   });
   $("#back-top").click(function () {
      $("html, body").stop().animate(
         {
            scrollTop: 0,
         },
         1500,
         "easeInOutExpo"
      );
   });
   ready();
   $("#announcementModal2").modal("show");
   // let viewed = getCookie("announced3");
   // viewed = !viewed ? 0 : parseInt(viewed);
   // if (viewed < 3) {
   //    setCookie("announced3", viewed + 1, 5);
   //    // $("#announcementModal").on("hidden.bs.modal", function () {
   //    //    $("#announcementModal2").modal("show");
   //    //    setTimeout(() => {}, 10);
   //    // });
   // }
});

function setCookie(name, value, days) {
   var expires = "";
   if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
   }
   document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
   var nameEQ = name + "=";
   var ca = document.cookie.split(";");
   for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
   }
   return null;
}
function eraseCookie(name) {
   document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
