import TronWeb from "tronweb";
import TronGrid from "trongrid";
import constants from "./constants";

const { contractAddress, network } = constants;

export async function fetchEvents(eventName, mapper) {
   let result = [];
   const eventsParams = {
      only_confirmed: true,
      event_name: eventName,
      // min_timestamp: Date.UTC(2021, 8, 27),
      limit: 200,
      fingerprint: "",
      order_by: "timestamp,asc",
      filters: {}, //if you need to filter events by one or more values, for example, by user id (if this information is presented in event log), remove if you don't need it.
   };
   const tronWeb = new TronWeb({
      fullHost: network, //todo change
   });
   const tronGrid = new TronGrid(tronWeb);
   try {
      let fingerprint = "";
      while (true) {
         const response = await tronGrid.contract.getEvents(contractAddress, {
            ...eventsParams,
            fingerprint: fingerprint,
         });
         if (!response.success) {
            console.warn("Can't get events for the contract");
            break;
         }

         result = result.concat(response.data.map(mapper));

         if (typeof response.meta.fingerprint !== "undefined") {
            console.log("Fetching the next page");
            fingerprint = response.meta.fingerprint;
         } else {
            break;
         }
      }
      return result;
   } catch (error) {
      console.log("Failed to fetch events: " + error);
   } finally {
      return result;
   }
}

export function userRefsCount(events, user) {
   let root = [user];
   let counts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
   for (let i = 0; i < 10; i++) {
      const childs = events
         .filter((r) => root.includes(r.referrer))
         .map((r) => r.user);
      if (childs.length === 0) break;
      counts[i] = childs.length;
      root = childs;
   }
   return counts;
}

export function isDownline(events, user, root) {
   root = [root];
   if (root.includes(user)) return true;
   while (true) {
      const childs = events.filter((r) => root.includes(r.referrer));
      if (childs.length === 0) return false;
      if (childs.filter((c) => c.referrer === user).length > 0) return true;
      root = childs.map((c) => c.user);
   }
}
