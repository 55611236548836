function notify({
   text = msg,
   node = null,
   duration = 3000,
   destination = "",
   newWindow = true,
   close = true,
   gravity = "bottom", // `top` or `bottom`
   position = "center", // `left`, `center` or `right`
   backgroundColor = "#694D9F",
   stopOnFocus = true, // Prevents dismissing of toast on hover
   onClick = function () {}, // Callback after click
}) {
   Toastify({
      text,
      node,
      duration,
      destination,
      newWindow,
      close,
      gravity,
      position,
      backgroundColor,
      stopOnFocus,
      onClick,
   }).showToast();
}

export function error(msg) {
   notify({ text: msg, backgroundColor: "#E26868" });
}
export function info(msg) {
   notify({ text: msg });
}
export function success(msg) {
   notify({ text: msg, backgroundColor: "#20A286" });
}

export function copyToClipboard(val) {
   const $temp = $("<input>");
   $("body").append($temp);
   $temp.val(val).select();
   document.execCommand("copy");
   $temp.remove();
}

export function now() {
   return new Date().getTime() / 1000;
}

export function timeParts(miliseconds) {
   const days = Math.floor(miliseconds / (60 * 60 * 24));
   const hours = Math.floor((miliseconds % 86400) / 3600);
   const minutes = Math.floor((miliseconds % 3600) / 60);
   const seconds = Math.floor(miliseconds % 60);
   return { days, hours, minutes, seconds };
}

export function timePartsStr(miliseconds) {
   const days = Math.floor(miliseconds / (60 * 60 * 24));
   const hours = Math.floor((miliseconds % 86400) / 3600);
   const minutes = Math.floor((miliseconds % 3600) / 60);
   const seconds = Math.floor(miliseconds % 60);

   return (
      (days > 0 ? days + "d : " : "") +
      hours +
      "h : " +
      minutes +
      "m : " +
      seconds +
      "s"
   );
}
