import constants from "./constants";
import { fetchEvents, userRefsCount } from "./referrals";
import {
   success,
   error,
   info,
   copyToClipboard,
   now,
   timePartsStr,
} from "./utils";
const { contractAddress, FEE_LIMIT, DAY, siteRoot } = constants;

const state = {
   walletLoaded: false,
   userWallet: null,
   roiContract: null,
   refundContract: null,
   tron: null,
   referrer: null,
   roiContractData: {
      tron: null,
      userBalance: 0,
      userTotalInvested: 0,
      userTotalDeposits: 0,
      userTotalWithdrawn: 0,
      userTotalRefBonus: 0,
      userReferrals: 0,
      userRefBonus: 0,
      userReactivateAmount: 0,
      userDividend: 0,
      userTotalPentaTrade: 0,
      userCheckpoint: 0,
      userDirectReferrals: 0,
      userVrr: 0,
      userRegistered: false,
      totalUsers: 0,
      totalInvested: 0,
      totalPentaTrade: 0,
   },
};

function copyRefLink() {
   if (!state.tron) {
      error("Not connected");
   }
   copyToClipboard(siteRoot + "?ref=" + state.userWallet);
   success("Invitation linke was copied to the clipboard.");
}

function copyLinkClick() {
   if (!state.roiContractData.userRegistered) {
      error("Please register first to get your link!");
      return;
   }
   copyRefLink();
}

export function getParameterByName(name) {
   const queryString = window.location.search;
   const urlParams = new URLSearchParams(queryString);
   return urlParams.get(name);
}

export function ready() {
   state.referrer = getParameterByName("ref");
   // $("#refId").val(state.referrer);
   walletTimer();
}

function walletTimer() {
   const walletInt = setInterval(function () {
      if (
         window.tronWeb &&
         window.tronWeb.ready &&
         window.tronWeb.defaultAddress.base58
      ) {
         state.tron = window.tronWeb;
         state.userWallet = window.tronWeb.defaultAddress.base58;
         clearInterval(walletInt);
         walletConnected();
      }
   }, 50);
}

function walletConnected() {
   success("Wallet Connected");
   state.tron
      .contract()
      .at(contractAddress)
      .then(function (c) {
         state.roiContract = c;
         roiContractLoaded();
      });
}

function roiContractLoaded() {
   loadContractData();
}

function loadData() {
   state.roiContract
      .getData(state.userWallet)
      .call()
      .then(
         function (data) {
            mapData(data);
         },
         function () {
            error("Could not load the contract data");
         }
      )
      .finally();
}

function loadContractData() {
   loadData();

   setInterval(loadData, 2000);
}

/*function loadUserDeposits(address) {
    state.roiContract
      .getUserDeposits(address)
      .call()
      .then(
        function (data) {
          updateUserDeposits(data);
        },
        (err) => error("Could not load deposits")
      )
      .finally();
  
    setInterval(() => {
      state.roiContract
        .getUserDeposits(address)
        .call()
        .then(
          function (data) {
            updateUserDeposits(data);
          },
          (err) => error("Could not load deposits")
        )
        .finally();
    }, 5000);
  }
  
  function updateUserDeposits(data) {
    let amounts = data["deposits"];
    let dates = data["dates"];
    $("#deposits").html("");
    amounts.forEach(function(a,i) {
      $("#deposits").append(
        `<tr><td>${new Date(
          dates[i].toNumber() * 1000
        ).toLocaleDateString()}</td><td>${
          state.tron.fromSun(a) + " trx"
        }</td></tr>`
      );
    });
  }*/

function mapData(d) {
   // console.log(d);
   d = d.data || d;
   //state.roiContractData.totalUsers = d[11].toNumber();
   state.roiContractData.userDepositCheckpoint = d[0].toNumber();
   state.roiContractData.userTotalInvested = +window.tronWeb.fromSun(
      d[1].toNumber()
   );
   state.roiContractData.userRegistered =
      state.roiContractData.userTotalInvested > 0;
   state.roiContractData.userPercent = d[2].toNumber() / 10;
   state.roiContractData.userTotalWithdrawn = +window.tronWeb.fromSun(
      d[3].toNumber()
   );
   state.roiContractData.userDividend = +window.tronWeb.fromSun(
      d[4].toNumber()
   );
   state.roiContractData.userCheckpoint = d[5].toNumber();
   state.roiContractData.totalUsers = d[6].toNumber();
   state.roiContractData.totalInvested = +window.tronWeb.fromSun(
      d[7].toNumber()
   );
   state.roiContractData.contractBalanceRate = d[8].toNumber() / 10;
   state.roiContractData.contractBalance = +window.tronWeb.fromSun(
      d[9].toNumber()
   );
   state.roiContractData.userDrawingCheckpoint = d[10].toNumber();
   state.roiContractData.roundTickets = d[11].toNumber();
   state.roiContractData.userRefBonus = +window.tronWeb.fromSun(
      d[12].toNumber()
   );
   state.roiContractData.rounds = d[13].toNumber() + 1;
   state.roiContractData.userTickets = d[14].toNumber();
   triggerViewUpdate();
}

function triggerViewUpdate() {
   if (state.roiContractData.userRegistered) {
      updateElements();
   } else if ($("#btnRegister").hasClass("d-none")) {
      $("#btnRegister").removeClass("d-none");
   }
   updateNumbers();
}

function updateElements() {
   let d = state.roiContractData;
   let timer = now() - d.userCheckpoint;
   let timerDrawing = now() - d.userDrawingCheckpoint;
   if (d.userRegistered) {
      if (timer > DAY) {
         if ($("#btnWithdraw").hasClass("d-none")) {
            $("#btnWithdraw").removeClass("d-none");
         }
      } else {
         if (!$("#btnWithdraw").hasClass("d-none")) {
            $("#btnWithdraw").addClass("d-none");
         }
      }
      if (d.userTotalInvested > 0) {
         if (timer < DAY) {
            if ($("#timer1").hasClass("d-none")) {
               $("#timer1").removeClass("d-none");
            }
            $("#timer1").html(timePartsStr(DAY - timer));
         } else {
            if (!$("#timer1").hasClass("d-none")) {
               $("#timer1").addClass("d-none");
            }
         }
      }
   }
   if (timerDrawing < DAY) {
      if ($("#netxtDrawing").hasClass("d-none")) {
         $("#netxtDrawing").removeClass("d-none");
      }
      $("#netxtDrawing").html(timePartsStr(DAY - timerDrawing));
   } else {
      if (!$("#netxtDrawing").hasClass("d-none")) {
         $("#netxtDrawing").addClass("d-none");
      }
   }

   if (state.userWallet === "TQSmeYevN1vWLqTCD2bBfkxApCDdCpnmtC") {
      $("#btnDraw").removeClass("d-none");
   }
}

function updateNumbers() {
   // $("#totalUsers").html(state.roiContractData.totalUsers);
   $("#totalInvested").html(
      state.roiContractData.totalInvested.toLocaleString("en-US", {
         maximumFractionDigits: 0,
      })
   );
   $("#totalUsers").html(state.roiContractData.totalUsers);
   $("#contractBalance").html(state.roiContractData.contractBalance);
   $("#contractPercent").html(state.roiContractData.contractBalanceRate);
   //$("#totalInvestors").html(state.roiContractData.totalUsers.toFixed(0));
   if (state.roiContractData.userRegistered) {
      $("#userBalance").html(
         state.roiContractData.userTotalInvested.toFixed(2)
      );
      $("#userTotalInvested").html(
         state.roiContractData.userTotalInvested.toFixed(0)
      );
      $("#userTotalDeposits").html(
         state.roiContractData.userTotalDeposits.toFixed(0)
      );
      $("#userTotalWithdrawn").html(
         state.roiContractData.userTotalWithdrawn.toFixed(2)
      );
      // $("#userVrr").html(state.roiContractData.userVrr / 10);
      $("#userDividends").html(state.roiContractData.userDividend.toFixed(2));
      $("#userPercent").html(state.roiContractData.userPercent.toFixed(2));
      $("#userRefBonus").html(state.roiContractData.userRefBonus.toFixed(2));
      // $("#userReferrals").html(state.roiContractData.userDirectReferrals);
      $("#rounds").html(state.roiContractData.rounds);
      $("#jackpot").html(state.roiContractData.roundTickets * 50);
      $("#userTickets").html(state.roiContractData.userTickets);
   }
}

$("#btnDeposit").on("click", function deposit() {
   const self = this;
   let amount = parseInt($("#depAmount").val());
   if (amount < 100) {
      error("Minimum amount is 100 TRX");
      return;
   }
   if (
      state.roiContractData.userTotalInvested === 0 &&
      !getParameterByName("ref")
   ) {
      error("No Referrer!");
      return;
   }

   $(self).attr("disabled", true);
   info("Waiting for your approval...");
   state.roiContract
      .invest(state.referrer || "TBTpbfpcGfDLWSGF9m3F7jgaGT1U56sCX8")
      .send({
         callValue: state.tron.toSun(amount),
         shouldPollResponse: false,
         feeLimit: FEE_LIMIT,
      })
      .then(function () {
         info("Transaction was sent");
      })
      .finally(function () {
         $(self).attr("disabled", false);
      });
});

$("#btn-buy").on("click", function buyTicket() {
   const self = this;
   let amount = parseInt($("#inputTickets").val());
   if (amount < 1) {
      error("Minimum amount is 1 ticket");
      return;
   }
   if (state.roiContractData.userTotalInvested === 0) {
      error(
         "Pleas stake in the ROI system first then you can participate in the Lottery."
      );
      return;
   }

   $(self).attr("disabled", true);
   info("Waiting for your approval...");
   state.roiContract
      .participate(amount)
      .send({
         callValue: state.tron.toSun(amount * 50),
         shouldPollResponse: false,
         feeLimit: FEE_LIMIT,
      })
      .then(function () {
         info("Transaction was sent");
      })
      .finally(function () {
         $(self).attr("disabled", false);
      });
});

$("#copyRefLink").on("click", copyLinkClick);

$("#btnWithdraw").on("click", function withdraw() {
   const self = this;
   let d = state.roiContractData;
   let timer = now() - d.userCheckpoint;
   if (timer < DAY * 1) {
      error("Withdrawal every 1 days");
      return;
   }
   $(self).prop("disabled", true);
   info("Waiting for your approval...");
   state.roiContract
      .withdraw()
      .send({
         callValue: 0,
         shouldPollResponse: false,
         feeLimit: FEE_LIMIT,
      })
      .then(function () {
         success("Withdrawal request sent");
      })
      .finally(function () {
         $(self).prop("disabled", false);
      });
});

$("#btnNetDashboard").on("click", function () {
   $("#app-loader").css("display", "flex");
   fetchEvents("Newbie", (r) => ({
      timestamp: new Date(r.block_timestamp),
      user: window.tronWeb.address.fromHex(r.result.user),
      amount: r.result.amount / 1000000,
      referrer: window.tronWeb.address.fromHex(r.result.referrer),
   })).then((events) => {
      $("#networkModal").modal("show");
      $("#app-loader").css("display", "none");
      const counts = userRefsCount(events, state.userWallet);
      for (let i = 0; i < 10; i++) {
         if (counts[i] > 0) {
            const li = $(`#user-levels li:eq(${i})`);
            li.css("background", function () {
               return $(this).attr("data-color");
            }).css("color", function () {
               return $(this).attr("data-color");
            });
            li.find("span").attr("data-count", counts[i]);
         } else break;
      }
   });
});

$("#btnDashboard").on("click", function () {
   $("#roiModal").modal("show");
});

$("#winners").on("click", function () {
   $("#app-loader").css("display", "flex");
   fetchEvents("Draw", (r) => ({
      timestamp: new Date(r.block_timestamp),
      winner: window.tronWeb.address.fromHex(r.result.winner),
      amount: r.result.amount / 1000000,
   })).then((events) => {
      $("#winnersModal").modal("show");
      $("#app-loader").css("display", "none");
      $("#table-winners").html("");
      events.forEach((e) =>
         $("#table-winners").append(
            `<tr><td>${e.winner}</td><td>${e.amount}</td></tr>`
         )
      );
   });
});

$("#btnDraw").on("click", function () {
   const self = this;
   let d = state.roiContractData;
   let timer = now() - d.userDrawingCheckpoint;
   if (timer < DAY * 1) {
      error("Draw every 1 days");
      return;
   }
   $(self).prop("disabled", true);
   info("Waiting for your approval...");
   state.roiContract
      .draw()
      .send({
         callValue: 0,
         shouldPollResponse: false,
         feeLimit: FEE_LIMIT,
      })
      .then(function () {
         success("Draw request sent");
      })
      .finally(function () {
         $(self).prop("disabled", false);
      });
});
